import Api from "@/services/Api";

export const report = {
    namespaced: true,
    state: {
        data: null,
        brand_id: null,
        min_date:null,
        max_date:null,
    },
    mutations: {
        setData(state, object)
        {
            state.data     = object.data;
            state.brand_id = object.brand_id;
            let min = new Date().toISOString().split('T')[0];
            let max = new Date(1970,1,1).toISOString().split('T')[0];
            if(Array.isArray(object.data))
            {
                object.data.forEach(function(row){
                    if(row && row.date && row.date < min)
                        min = row.date;
                    if(row && row.date && row.date > max)
                        max = row.date;
                })
            }
            state.min_date = min;
            state.max_date = max;
        }
    },
    getters: {
        getCurrentData(state)
        {
            return state.data;
        },
        getMinReportDataDate(state)
        {
            return state.min_date;
        },
        getMaxReportDataDate(state)
        {
            return state.max_date;
        },
        getCurrentDataBrandId(state)
        {
            return state.brand_id;
        }
    },
    actions: {
        clearData({commit})
        {
           commit("setData", {data: false, brand_id: false});
        },
        getReportData({commit, state}, brand_id)
        {
            if(brand_id == state.brand_id)
                return state.data;
            commit("setData", {data: false, brand_id: false});
            return new Promise( (success, failure) =>
                               {
                                   Api.workspaceGet("reports/campaign?brand=" + brand_id)
                                            .then(
                                                    response =>
                                                    {
                                                        commit("setData", {data: response.data, brand_id: brand_id});
                                                        success(response.data);
                                                    },
                                                    err =>
                                                    {
                                                        console.log(err);
                                                        failure(false);
                                                    }
                                            );
                               });
        }
    }
}